const Colors = {
  text: '#11181C',
  background: '#fff',
  accent: '#7338A0',
  secondary: '#687076',
};

export const legalStyles = {
  container: {
    flex: 1,
    backgroundColor: Colors.background,
    minHeight: '100vh',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    paddingTop: '100px',
    gap: '20px',
  },
  title: {
    fontSize: '32px',
    fontWeight: '600',
    color: `${Colors.accent} !important`,
    marginBottom: '24px',
    background: 'none',
    WebkitBackgroundClip: 'unset',
    WebkitTextFillColor: 'initial',
  },
  text: {
    fontSize: '16px',
    color: Colors.secondary,
    lineHeight: 1.6,
    maxWidth: '800px',
    textAlign: 'left',
  }
}; 