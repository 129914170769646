import React from 'react';
import { legalStyles } from '../styles/legalStyles';

function Privacy() {
  return (
    <div style={legalStyles.container}>
      <div style={legalStyles.content}>
        <h1 style={legalStyles.title}>Privacy Policy</h1>
        <p style={legalStyles.text}>Last Updated: January 1, 2025</p>

        <h2 style={legalStyles.title}>Overview</h2>
        <p style={legalStyles.text}>
          This Privacy Policy describes how we handle personal information collected through our platform that facilitates peer-to-peer digital dollar (USDC) transactions. Our service provides technology to help users create self-custodial digital wallets and interact with the Base network. Users maintain full control of their assets at all times.
        </p>

        <h2 style={legalStyles.title}>Personal Information We Collect</h2>
        
        <h3 style={legalStyles.title}>Information you provide to us:</h3>
        <p style={legalStyles.text}>
          • Phone number for wallet creation and authentication<br/>
          • Communications when you contact us for support<br/>
          • Merchant contact information (for those accepting USDC payments)
        </p>

        <h3 style={legalStyles.title}>Information automatically collected:</h3>
        <p style={legalStyles.text}>
          Device data:<br/>
          • Operating system and version<br/>
          • Browser type and version<br/>
          • IP address<br/>
          • Mobile device identifiers<br/>
          • Language settings<br/>
          • General location (city/state level)<br/><br/>

          Usage data:<br/>
          • Pages viewed on our platform<br/>
          • Time spent on pages<br/>
          • Navigation patterns<br/>
          • Access times and dates<br/>
          • Platform interaction data
        </p>

        <h3 style={legalStyles.title}>Information from third parties:</h3>
        <p style={legalStyles.text}>
          • Blockchain transaction data (publicly available on Base network)<br/>
          • Identity verification data from Coinbase (only if you choose to use their on/off ramp services)
        </p>

        <h2 style={legalStyles.title}>How We Use Your Information</h2>

        <h3 style={legalStyles.title}>Platform Operations:</h3>
        <p style={legalStyles.text}>
          • Facilitate wallet creation through Privy<br/>
          • Display transaction information<br/>
          • Provide customer support<br/>
          • Communicate service updates<br/>
          • Improve platform functionality
        </p>

        <h3 style={legalStyles.title}>Security:</h3>
        <p style={legalStyles.text}>
          • Prevent unauthorized access<br/>
          • Maintain platform security<br/>
          • Protect user rights<br/>
          • Audit internal processes
        </p>

        <h3 style={legalStyles.title}>Service Improvement:</h3>
        <p style={legalStyles.text}>
          • Analyze platform performance<br/>
          • Improve user experience<br/>
          • Develop new features<br/>
          • Generate anonymous usage statistics<br/>
          • Resolve technical issues
        </p>

        <h2 style={legalStyles.title}>Information Sharing</h2>
        <p style={legalStyles.text}>We share your information with:</p>

        <h3 style={legalStyles.title}>Service Providers:</h3>
        <p style={legalStyles.text}>
          • Privy (self-custodial wallet technology)<br/>
          • Cloud hosting providers<br/>
          • Customer support systems<br/>
          • Analytics services
        </p>

        <h3 style={legalStyles.title}>Public Blockchain:</h3>
        <p style={legalStyles.text}>
          • Transaction records are publicly visible on the Base network<br/>
          • Only transaction data, no personal identifying information
        </p>

        <h3 style={legalStyles.title}>Legal Requirements:</h3>
        <p style={legalStyles.text}>
          • Law enforcement when required by valid legal process<br/>
          • Government authorities with valid requests<br/>
          • Parties involved in legal proceedings when necessary
        </p>

        <h3 style={legalStyles.title}>Business Transfers:</h3>
        <p style={legalStyles.text}>
          • In case of merger, acquisition, or sale of assets<br/>
          • With notice to affected users
        </p>

        <h2 style={legalStyles.title}>Your Choices</h2>
        <p style={legalStyles.text}>
          You can:<br/>
          • Choose not to provide certain information<br/>
          • Opt out of marketing communications<br/>
          • Delete your account (blockchain transactions remain public)<br/>
          • Request your data (subject to legal requirements)
        </p>

        <h2 style={legalStyles.title}>Security</h2>
        <p style={legalStyles.text}>
          We implement strong security measures:<br/>
          • Encryption of personal data<br/>
          • Self-custodial wallet technology<br/>
          • Regular security audits<br/>
          • Limited data collection<br/>
          • Secure authentication methods<br/><br/>

          However, no internet transmission is 100% secure. We cannot guarantee absolute security but maintain industry-standard protection measures.
        </p>

        <h2 style={legalStyles.title}>Children's Privacy</h2>
        <p style={legalStyles.text}>
          Our platform is not intended for children under 13. We do not knowingly collect information from children under 13. If we learn we have collected such information, we will delete it immediately.
        </p>

        <h2 style={legalStyles.title}>International Users</h2>
        <p style={legalStyles.text}>
          We process data in the United States. By using our platform, you consent to data transfer to the U.S. and other locations where we and our partners operate.
        </p>

        <h2 style={legalStyles.title}>Changes to This Policy</h2>
        <p style={legalStyles.text}>
          We may update this Privacy Policy. We will notify you of material changes by:<br/>
          • Updating the "Last Updated" date<br/>
          • Posting notice on our platform<br/>
          • Sending email notifications when required
        </p>

        <h2 style={legalStyles.title}>Contact Us</h2>
        <p style={legalStyles.text}>
          For privacy questions or concerns:<br/>
          Email: jamesstevens789@gmail.com
        </p>

        <h2 style={legalStyles.title}>State Privacy Rights</h2>
        <p style={legalStyles.text}>
          Various states provide their residents with specific rights regarding their personal information. Contact us to exercise any applicable rights under your state's laws.
        </p>

        <h2 style={legalStyles.title}>Do Not Track</h2>
        <p style={legalStyles.text}>
          We currently do not respond to "Do Not Track" browser signals.
        </p>
      </div>
    </div>
  );
}

export default Privacy; 