import React from 'react';
import { legalStyles } from '../styles/legalStyles';

function Terms() {
  return (
    <div style={legalStyles.container}>
      <div style={legalStyles.content}>
        <h1 style={legalStyles.title}>Terms of Service</h1>
        <p style={legalStyles.text}>Last Updated: January 1, 2025</p>

        <h2 style={legalStyles.title}>1. Service Overview</h2>
        <p style={legalStyles.text}>
          Our platform provides a secure digital payment system that enables transactions using USD Coin (USDC), a regulated digital dollar backed 1:1 by cash and cash equivalents held in U.S. regulated financial institutions. USDC is issued by Circle Internet Financial, LLC, a regulated U.S. company, and each USDC token is always redeemable for one U.S. dollar.
        </p>

        <h3 style={legalStyles.title}>1.1 Digital Wallet Creation</h3>
        <p style={legalStyles.text}>
          • Upon providing your phone number, we create a secure digital wallet using Privy's embedded wallet technology<br/>
          • Your wallet and its security credentials remain under your exclusive control<br/>
          • We cannot access your wallet credentials or initiate transactions without your explicit approval
        </p>

        <h3 style={legalStyles.title}>1.2 Payment Processing</h3>
        <p style={legalStyles.text}>
          • Our platform facilitates tap-to-pay transactions using USDC on the Base network<br/>
          • All transactions require your explicit review and confirmation<br/>
          • Transaction amounts are clearly displayed in U.S. dollars<br/>
          • Payments are processed in USDC at a 1:1 ratio with U.S. dollars
        </p>

        <h3 style={legalStyles.title}>1.3 Currency Conversion Services</h3>
        <p style={legalStyles.text}>
          • Digital dollar (USDC) acquisition is facilitated through Coinbase's regulated on-ramp service<br/>
          • Conversion of USDC back to traditional U.S. dollars is available through Coinbase's regulated off-ramp service<br/>
          • Coinbase manages all required Know Your Customer (KYC) procedures for currency conversion services<br/>
          • Users must comply with Coinbase's verification requirements for currency conversion services
        </p>

        <h2 style={legalStyles.title}>2. USDC Digital Dollar Specifications</h2>
        <p style={legalStyles.text}>
          USDC is designed to maintain stable parity with the U.S. dollar:<br/>
          • Fully backed 1:1 by cash and cash equivalents in U.S. regulated financial institutions<br/>
          • Regular attestations by leading accounting firms verify the 1:1 backing<br/>
          • Issued by Circle, a regulated U.S. financial technology company<br/>
          • Supported by major U.S. financial institutions<br/>
          • Fully redeemable: 1 USDC can always be redeemed for 1 U.S. dollar<br/>
          • Transparent reserves: Monthly attestation reports are publicly available
        </p>

        <h2 style={legalStyles.title}>3. Security and Control</h2>
        <h3 style={legalStyles.title}>3.1 Non-Custodial Service</h3>
        <p style={legalStyles.text}>
          Our platform:<br/>
          • Does not take custody of your funds<br/>
          • Cannot access your wallet's private keys<br/>
          • Cannot initiate transactions without your explicit approval<br/>
          • Cannot modify transaction amounts or recipients<br/>
          • Cannot access your funds in any way
        </p>

        <h3 style={legalStyles.title}>3.2 Transaction Security</h3>
        <p style={legalStyles.text}>
          Each payment requires:<br/>
          • Clear display of the transaction amount in U.S. dollars<br/>
          • Your explicit review and confirmation<br/>
          • Secure transaction signing using your personal wallet<br/>
          • Verification through the Base network
        </p>

        <h2 style={legalStyles.title}>4. Merchant Services</h2>
        <p style={legalStyles.text}>
          For merchants accepting digital dollar payments:<br/>
          • Receive USDC digital dollars instantly<br/>
          • Each USDC maintains exact 1:1 parity with U.S. dollars<br/>
          • Full transparency of transaction history<br/>
          • Ability to convert to traditional U.S. dollars through Coinbase's regulated service<br/>
          • Real-time transaction verification<br/>
          • Clear display of payment amounts in U.S. dollars<br/>
          • Integration with existing point-of-sale systems where applicable
        </p>

        <h2 style={legalStyles.title}>5. User Responsibilities</h2>
        <p style={legalStyles.text}>
          Users are responsible for:<br/>
          • Maintaining the security of their phone number and authentication methods<br/>
          • Reviewing transaction details before approval<br/>
          • Ensuring sufficient funds for intended transactions<br/>
          • Complying with Coinbase's KYC requirements for currency conversion<br/>
          • Understanding that all transactions on the Base network are final<br/>
          • Maintaining accurate contact information<br/>
          • Reporting any unauthorized transactions immediately
        </p>

        <h2 style={legalStyles.title}>6. Regulatory Compliance</h2>
        <p style={legalStyles.text}>
          Our service operates in compliance with applicable U.S. regulations:<br/>
          • USDC is issued by Circle, a regulated U.S. financial services company<br/>
          • Currency conversion services are provided by Coinbase, a licensed U.S. financial institution<br/>
          • All transaction records are maintained in accordance with applicable laws<br/>
          • Users must comply with all relevant financial regulations and KYC requirements
        </p>

        <h2 style={legalStyles.title}>7. Fees and Limits</h2>
        <h3 style={legalStyles.title}>7.1 Transaction Fees</h3>
        <p style={legalStyles.text}>
          • No fees for users sending payments<br/>
          • Merchants are charged a 1% fee on received payments<br/>
          • Merchant fee rates are subject to change with advance notice<br/>
          • Currency conversion fees may apply when using Coinbase services
        </p>

        <h3 style={legalStyles.title}>7.2 Transaction Limits</h3>
        <p style={legalStyles.text}>
          • No transaction limits are imposed by our platform<br/>
          • Currency conversion limits may apply through Coinbase services
        </p>

        <h2 style={legalStyles.title}>8. Privacy and Data Protection</h2>
        <p style={legalStyles.text}>
          We protect your privacy by:<br/>
          • Securing all personal information with industry-standard encryption<br/>
          • Only collecting necessary information for service operation<br/>
          • Never sharing your personal information without your consent<br/>
          • Maintaining compliance with applicable privacy laws and regulations
        </p>

        <h2 style={legalStyles.title}>9. Disclaimers and Limitations</h2>
        <p style={legalStyles.text}>
          While USDC is designed to maintain 1:1 parity with the U.S. dollar:<br/>
          • We do not guarantee any specific exchange rate<br/>
          • Network conditions may affect transaction timing<br/>
          • Currency conversion services are subject to Coinbase's terms and availability<br/>
          • We are not responsible for any losses due to user error or network conditions
        </p>

        <h2 style={legalStyles.title}>10. Support and Contact</h2>
        <p style={legalStyles.text}>
          For assistance with:<br/>
          • Payment processing issues<br/>
          • Merchant services<br/>
          • Technical support<br/>
          • General inquiries<br/><br/>
          Contact: jamesstevens789@gmail.com
        </p>

        <h2 style={legalStyles.title}>11. Changes to Terms</h2>
        <p style={legalStyles.text}>
          We reserve the right to modify these terms with appropriate notice to users. Continued use of the service after such modifications constitutes acceptance of the updated terms.
        </p>
      </div>
    </div>
  );
}

export default Terms; 